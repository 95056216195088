<template>
  <div>
    <!-- Form Modal -->
    <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc @ok="submit" size="md">
      <!-- Custom Modal Header -->
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 v-if="input.id === null">Register Inputs</h5>
        <h5 v-if="input.id !== null">Edit Grade</h5>

        <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
      </template>

      <!-- Custom Modal Footer -->
      <template #modal-footer="{ ok, cancel }">
        <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
          <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
          <span v-if="!saving">Save</span>
          <span v-if="saving">Saving...</span>
        </b-button>
        <b-button size="md" @click="cancel()" variant="outline-secondary"> Cancel </b-button>
      </template>

      <!-- Data Form in Modal Body -->
      <template #default="{}">
        <validation-observer ref="dataForm" #default="{ invalid }">
          <b-form ref="form" @submit.stop.prevent="handleCreate">
            <b-row>
              <b-col cols="12" md="8">
                <b-form-group labe-for="name" label="Name">
                  <validation-provider #default="{ errors }" name="name" rules="">
                    <b-form-input
                      id="name"
                      name="name"
                      size="sm"
                      v-model="input.name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="serverErrors && serverErrors.name">{{ serverErrors.name[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group labe-for="unit_id" label="Unit" style="cursor: pointer">
                  <validation-provider #default="{ errors }" name="unit_id" rules="">
                    <b-form-select
                      id="unit_id"
                      name="unit_id"
                      size="sm"
                      v-model="input.unit_id"
                    >
                      <b-form-select-option :value="null">Select...</b-form-select-option>
                      <b-form-select-option
                        v-for="unit in units"
                        :key="unit.id"
                        :value="unit.id"
                      >
                        {{ unit.name }} ({{ unit.abbreviation }})
                      </b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="serverErrors && serverErrors.unit_id">{{ serverErrors.unit_id[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <fieldset v-if="input.unit_id !== null && units.filter((unit) => unit.id == input.unit_id)[0].needDescription">
              <legend style="font-size:14px;">Packaging Description</legend>
              <hr />
              <b-row v-if="input.unit_id !== null && units.filter((unit) => unit.id == input.unit_id)[0].needDescription">
                  <b-col cols="12" md="4">
                    <b-form-group label-for="descriptionAmount" label="Amount">
                      <validation-provider #default="{ errors }" name="descriptionAmount" rules="">
                        <b-form-input
                          id="descriptionAmount"
                          name="descriptionAmount"
                          size="sm"
                          v-model="input.descriptionAmount"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small class="text-danger" v-if="serverErrors && serverErrors.descriptionAmount">{{ serverErrors.descriptionAmount[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="4">
                    <b-form-group label-for="description_id" label="Unit" style="cursor: pointer">
                      <validation-provider #default="{ errors }" name="description_id" rules="">
                        <b-form-select
                          id="description_id"
                          name="description_id"
                          size="sm"
                          v-model="input.description_id"
                        >
                          <b-form-select-option :value="null">Select...</b-form-select-option>
                          <b-form-select-option v-for="unit in units.filter((unit) => !unit.needDescription)" :key="unit.id" :value="unit.id">
                            {{ unit.abbreviation }}
                          </b-form-select-option>
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small class="text-danger" v-if="serverErrors && serverErrors.description_id">{{ serverErrors.description_id[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
              </b-row>
            </fieldset>

            <fieldset>
              <legend style="font-size:14px;">Recommended Application</legend>
              <hr />

              <b-row>
                <b-col cols="12" md="4">
                  <b-form-group label-for="recommended" label="Per Hectare">
                    <validation-provider #default="{ errors }" name="recommended" rules="">
                      <b-form-input
                        id="recommended"
                        name="recommended"
                        size="sm"
                        v-model="input.recommended"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger" v-if="serverErrors && serverErrors.recommended">{{ serverErrors.recommended[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cls="12" md="4">
                  <b-form-group label-for="per_id" label="Unit" style="cursor: pointer">
                    <validation-provider #default="{ errors }" name="per_id" rules="">
                      <b-form-select
                        id="per_id"
                        name="per_id"
                        size="sm"
                        v-model="input.per_id"
                      >
                        <b-form-select-option :value="null">Select...</b-form-select-option>
                        <b-form-select-option v-if="input.unit_id != null" v-for="unit in units.filter((unit) => unit.id == input.unit_id)" :key="unit.id" :value="unit.id">
                          {{ unit.abbreviation }}
                        </b-form-select-option>
                        <b-form-select-option v-if="input.description_id != null" v-for="unit in units.filter((unit) => unit.id == input.description_id)" :key="unit.id" :value="unit.id">
                          {{ unit.abbreviation }}
                        </b-form-select-option>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger" v-if="serverErrors && serverErrors.per_id">{{ serverErrors.per_id[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </fieldset>

            <fieldset>
              <legend style="font-size:14px;">Pricing</legend>
              <b-row style="border:1px solid #ccc;border-radius: 4px;padding-top: 3px; padding-bottom: 3px;">
                <b-col cols="12" md="12">
                  <b-row>
                    <b-col cols="12" md="12">
                      <b-form-checkbox
                        v-model="pricing"
                        :value="{ pricing_id: client.id, pricing_type: 0 }"
                        class="custom-control-success"
                        @change="changePricing()"
                      >
                        Country Wide
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row  v-for="selection in selections" :key="selection.id" class="mt-2">
                    <b-col cols="12" md="12">
                      <b-form-checkbox
                        v-model="pricing"
                        :value="{ pricing_id: selection.id, pricing_type: 1 }"
                        class="custom-control-success"
                        @change="changePricing()"
                      >
                        {{ selection.label }}
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </fieldset>

            <!-- <pre>
              {{ input }}
            </pre> -->
          </b-form>
        </validation-observer>
      </template>
    </b-modal>
    <!-- ./Form Modal -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50 select-size-sm" />
            <label>entries</label>
          </b-col>
          <!-- ./Per Page -->

          <!-- Search & Button -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." size="sm" />
              <b-button variant="primary" size="sm" @click="invokeCreateForm()">
                <span class="text-nowrap">Register Inputs</span>
              </b-button>
            </div>
          </b-col>
          <!-- ./Search & Button-->
        </b-row>
        <!-- ./Table Top -->
      </div>

      <!-- Table -->
      <b-table
        :busy="isBusy"
        striped
        hover
        small
        ref="records"
        class="position-relative"
        :items="fetch"
        responsive
        :fields="columns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(units)="data"> {{ data.item.units[data.item.units.length - 1].name }} ({{ data.item.units[data.item.units.length - 1].abbreviation }}) </template>
        <template #cell(pricing)="data">
          {{ data.item.pricing.description }}
        </template>
        <template #cell(packaging)="data">
          <span v-if="data.item.packaging">{{ data.item.packaging.amount }} {{ data.item.packaging.unit }} </span>
        </template>
        <template #cell(prices)="data">
          <span v-if="data.item.prices.length > 0">
            {{ formatPrice(data.item.prices[data.item.prices.length - 1].value) }}
            {{ data.item.prices[data.item.prices.length - 1].currency.abbreviation }}
          </span>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="invokeUpdateForm(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="remove(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- Table Footer -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination, BForm, BFormGroup, BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox, BSpinner, BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "@validations";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import inputsStoreModule from "@/views/cromis/inputs/inputsStoreModule";
import useInputsList from "@/views/cromis/inputs/useInputsList";

export default {
  props: {},
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BPagination,
    BForm,
    BFormGroup,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormInput,
    vSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BFormCheckbox,
    BSpinner,
    BBadge,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {},
  setup(props, context) {
    const dataForm = ref(null);
    const myModal = ref(null);
    const saving = ref(false);
    const serverErrors = ref(null);

    const levels = ref(JSON.parse(localStorage.getItem('userData')).levels != null ? JSON.parse(localStorage.getItem('userData')).levels : [])
    const client = ref(JSON.parse(localStorage.getItem('userData')).client)
    const selections = ref([])
    const children = ref([])

    const units = ref([]);
    const pricing = ref([])
    const input = ref({
      id: null,
      name: null,
      recommended: null,
      unit_id: null,
      per_id: null,
      descriptionAmount: null,
      description_id: null,
      pricing_id: pricing.value.length > 0 ? pricing.value[0].pricing_id : null,
      pricing_type: pricing.value.length > 0 ? pricing.value[0].pricing_type : null,
    });

    const CROMIS_STORE_MODULE_NAME = "cromis-input";

    // Register module
    if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) {
      store.registerModule(CROMIS_STORE_MODULE_NAME, inputsStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CROMIS_STORE_MODULE_NAME)) {
        store.unregisterModule(CROMIS_STORE_MODULE_NAME);
      }
    });

    onMounted(async () => {
      if(client.value != null){
        pricing.value.push({
          pricing_id: client.value.id,
          pricing_type: 0 // 0 = client, 1 = level
        })
      }

      if(levels.value.length > 0){
        levels.value = levels.value[0]

        selections.value.push({
          id: levels.value.id,
          level: levels.value.name.toLowerCase(),
          model: null,
          label: levels.value.name,
          child: levels.value.children[0].name.toLowerCase(),
          locations: [], 
        })

        let list = levels.value.children

        while(list.length > 0){
            children.value.push(list[0])

            selections.value.push({
              id: list[0].id,
              level: list[0].name.toLowerCase(),
              model: null,
              label: list[0].name,
              child: list[0].children.length > 0? list[0].children[0].name.toLowerCase() : null,
              locations: [],
            })

            list = list[0].children
        }
      }

      await store
        .dispatch("cromis-input/units")
        .then((response) => {
          units.value = response.data.units;
        })
        .catch((error) => {
          console.log("got error", error);
        });
    });

    const {
      fetch,
      columns,
      perPage,
      currentPage,
      totalRecords,
      from,
      to,
      meta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      records,
      refetch,
      isBusy,
      // Filers
      season_id,
      // Resolvers
      resolveStatusVariant,
    } = useInputsList();

    // Form Methods
    const invokeUpdateForm = (item) => {
      console.log(item.recommended);
      serverErrors.value = null;

      input.value = {
        id: item.id,
        name: item.name,
        unit_id: item.units[0],
        descriptionAmount: item.packaging.amount,
        description_id: item.packaging.description_id,
        recommended: item.recommended,
        per_id: item.per_id,

        pricing_id: item.pricing_id,
        pricing_type: item.pricing_type,
      };

      myModal.value.show();
    };

    const invokeCreateForm = () => {
      serverErrors.value = null;

      input.value = {
        id: null,
        name: null,
        recommended: null,
        unit_id: null,
        per_id: null,
        descriptionAmount: null,
        description_id: null,
        pricing_id: pricing.value.length > 0 ? pricing.value[0].pricing_id : null,
        pricing_type: pricing.value.length > 0 ? pricing.value[0].pricing_type : null,
      }

      myModal.value.show();
    };

    const changePricing = () => {
      if(pricing.value.length == 0){
        pricing.value.push({
          pricing_id: client.value.id,
          pricing_type: 0 // 0 = client, 1 = level
        })
      }
      else{
        while(pricing.value.length > 1){
          pricing.value.splice(0, 1)
        }
      }

      input.value.pricing_id = pricing.value.length > 0 ? pricing.value[0].pricing_id : null
      input.value.pricing_type = pricing.value.length > 0 ? pricing.value[0].pricing_type : null
    }

    const isFormValid = async () => {
      let isValid = false;
      await dataForm.value.validate().then((success) => {
        isValid = success;
      });
      return isValid;
    };

    const submit = (bvModalEvt) => {
      bvModalEvt.preventDefault();
      serverErrors.value = null;
      // Handle form submit
      if (input.value.id === null || input.value.id === 0) {
        handleCreate();
      } else {
        handleUpdate(input.value);
      }
    };

    const handleCreate = async () => {
      saving.value = true;

      const valid = await isFormValid();
      if (!valid) {
        saving.value = false;
        return;
      }

      await store
        .dispatch("cromis-input/create", input.value)
        .then((response) => {
          refetch();
          saving.value = false;
          myModal.value.hide();
          context.root.$swal({
            icon: "success",
            text: `Grade ${response.data.name} registered successfully!`,
            showConfirmButton: true,
            timer: 3000,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          saving.value = false;
          if (error.response.status === 422) {
            serverErrors.value = error.response.data.errors;
          } else {
            context.root.$swal({
              icon: "error",
              title: "Server Error",
              text: "Something went wrong. See tech support",
              showConfirmButton: true,
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
    };

    const handleUpdate = async (item) => {
      saving.value = true;
      const valid = await isFormValid();

      if (!valid) {
        saving.value = false;
        return;
      }

      await store
        .dispatch("cromis-input/update", { id: item.id, data: item })
        .then((response) => {
          refetch();
          saving.value = false;

          myModal.value.hide();

          context.root.$swal({
            icon: "success",
            text: `Changes to input # ${response.data.name} has been saved successfully!`,
            showConfirmButton: true,
            timer: 3000,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          saving.value = false;
          if (error.response.status === 422) {
            serverErrors.value = error.response.data.errors;
          } else {
            context.root.$swal({
              icon: "error",
              title: "Server Error",
              text: "Something went wrong. See tech support",
              showConfirmButton: true,
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
    };

    const remove = async (id) => {
      await store
        .dispatch("cromis-input/remove", id)
        .then((response) => {
          refetch();
        })
        .catch((error) => {
          context.root.$swal({
            icon: "error",
            title: "Server Error",
            text: "Something went wrong. See tech support",
            showConfirmButton: true,
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: true,
          });
        });
    };

    return {
      // Data
      client,
      selections,
      levels,
      units,
      pricing,
      input,      

      fetch,
      columns,
      perPage,
      currentPage,
      totalRecords,
      from,
      to,
      meta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      records,
      refetch,
      isBusy,

      // Methods
      required,
      saving,
      serverErrors,
      dataForm,
      myModal,
      invokeCreateForm,
      invokeUpdateForm,
      submit,
      isFormValid,
      handleCreate,
      handleUpdate,
      remove,
      changePricing,
      resolveStatusVariant,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>
